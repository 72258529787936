import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { InfoMessage } from '@model/info-message';
import { Profile } from '@model/profile';
import { VerifyEmails } from '@model/verify-emails';
import { map, Observable, of, tap } from 'rxjs';

import { environment } from '../../../environments/environment';

import { AddressService } from './address.service';
import { BaseService } from './base.service';
import { StorageService } from './storage.service';
import { SvvwAuthenticationService } from "@service/authentication.service";

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends BaseService {
  private readonly storageService = inject(StorageService);
  private readonly addressService = inject(AddressService);
  private readonly keycloakService = inject(SvvwAuthenticationService);


  constructor(httpClient: HttpClient) {
    super(httpClient, 'persons');
  }

  getProfile(forceUpdate: boolean): Observable<Profile> {
    const sessionUser = this.storageService.getUser();
    if (!sessionUser || forceUpdate) {
      return this.getRemoteProfile().pipe(
        tap(value => this.storageService.setUser(value)),
      );
    } else {
      return new Observable<Profile>(observer => {
        observer.next(sessionUser);
        observer.complete();
      });
    }
  }

  /**
   * Call the HSV validate person changes endpoint.
   * @param profile The person object containing the changes
   * @return 200 OK if changes are valid.
   */
  validateHsvProfile(profile: Profile): Observable<void> {
    return this.httpClient.post<void>(`${this.endpointUrl}/validate`, profile);
  }

  getRemoteProfile(): Observable<Profile> {
    return this.httpClient.get<Profile>(this.endpointUrl);
  }

  setRemoteProfile(profile: Profile): Observable<Profile> {
    return this.httpClient.put<Profile>(this.endpointUrl, profile);
  }

  deleteProfile(): Observable<void> {
    // In non production environments do not delete the profile
    if (!environment.production) {
      return of(void 0);
    }

    return this.httpClient.delete<void>(this.endpointUrl);
  }

  verifyEmails(change: VerifyEmails): Observable<InfoMessage> {
    return this.httpClient.post<InfoMessage>(
      `${this.endpointUrl}/userVerifiedEmails`,
      change,
    );
  }

  emailsMatch(): Observable<boolean> {
    return this.getProfile(false).pipe(
      map(
        profile =>
          this.keycloakService.getUser().profile.email ===
          profile.email,
      ),
    );
  }

  isProfileComplete(person: Profile): boolean {
    if (person == null) return false;

    const requiredFields = [
      'dateOfBirth',
      'initials',
      'firstName',
      'lastName',
      'email',
      'gender',
    ];
    const complete = requiredFields.every(
      value => person[value] != null && value !== '',
    );

    if (
      complete &&
      person.personaddresses != null &&
      person.personaddresses.length > 0
    ) {
      return this.addressService.isAddressComplete(
        person.personaddresses[0].address,
      );
    }

    return false;
  }
}
