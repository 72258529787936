import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@svnl/shared';

@Injectable({
  providedIn: 'root',
})
export class SvvwAuthenticationService extends AuthenticationService {

  private router: Router = inject(Router);

  override async init(path: string): Promise<void> {
    await super.init(path);

    const commands = [''];

    if (this.getUser()) {
      return this.router.navigate(commands).then(() => undefined);
    } else {
      return this.login();
    }
  }

  public updatePassword(): void {
    this.userManager.signinRedirect({ extraQueryParams: { 'kc_action': 'UPDATE_PASSWORD'}});
  }
}
