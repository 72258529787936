import * as i0 from '@angular/core';
import { InjectionToken, inject, NgZone, signal, computed, Injectable, input, Component, Optional, Host, SkipSelf } from '@angular/core';
import { UserManager, Log } from 'oidc-client-ts';
import { App } from '@capacitor/app';
import { CapacitorRedirectNavigator } from '@flusso/capacitor-oidc-client-ts';
import { Browser } from '@capacitor/browser';
import { from, mergeMap, of, throwError, map } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import * as i1 from '@angular/forms';
import proj4 from 'proj4';
const fullAddress = address => {
  if (address) {
    const fullAddressText = `${address.street} ${address.housenumber}`;
    return address.addition ? `${fullAddressText} ${address.addition}` : fullAddressText;
  }
  return;
};
const filterArrayOnDefined = items => items.reduce((acc, item) => item ? [...acc, item] : acc, []);
const SVNL_SHARED_CONFIG = new InjectionToken('SVNL_SHARED_CONFIG');
class AuthenticationService {
  constructor() {
    this.sharedConfig = inject(SVNL_SHARED_CONFIG);
    this.zone = inject(NgZone);
    this.user = signal(null);
    this.getUser = computed(() => this.user());
    this.isUserExpired = computed(() => this.user()?.expired ?? true);
    this.userManager = new UserManager(this.sharedConfig.authentication.settings, new CapacitorRedirectNavigator());
    this.userManager.events.addUserLoaded(async () => this.user.set(await this.userManager.getUser()));
    this.userManager.events.addUserUnloaded(async () => this.user.set(await this.userManager.getUser()));
    App.addListener('appUrlOpen', event => this.onAppUrlOpen(event));
    App.addListener('resume', async () => await this.onResume());
    if (this.sharedConfig.authentication?.debugLogging && !this.sharedConfig.production) {
      Log.setLogger(console);
      Log.setLevel(Log.DEBUG);
    }
  }
  async init(path) {
    await this.signinSilent();
    if (path.includes('signin-callback')) {
      await this.userManager.signinCallback(path);
      await this.closeBrowser();
    } else if (path.includes('signout-callback')) {
      await this.userManager.signoutCallback(path);
      await this.closeBrowser();
    }
    this.user.set(await this.userManager.getUser());
  }
  async signinSilent() {
    const user = await this.userManager.getUser();
    if (user && user.expired) {
      // access_token expired, try refresh_token first
      try {
        const authenticatedUser = await this.userManager.signinSilent();
        if (!authenticatedUser) {
          await this.userManager.removeUser();
        }
      } catch (error) {
        console.error(`Error refreshing token : ${error}`);
        await this.userManager.removeUser();
      }
    }
  }
  async closeBrowser() {
    try {
      await Browser.close();
    } catch (error) {
      return;
    }
  }
  onAppUrlOpen(event) {
    this.zone.run(async () => this.init(event.url));
  }
  async onResume() {
    await this.signinSilent();
    this.user.set(await this.userManager.getUser());
  }
  async getToken() {
    const user = await this.userManager.getUser();
    return user?.access_token;
  }
  login() {
    return this.userManager.signinRedirect();
  }
  logout() {
    return this.userManager.signoutRedirect();
  }
  static {
    this.ɵfac = function AuthenticationService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || AuthenticationService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: AuthenticationService,
      factory: AuthenticationService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AuthenticationService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
const tokenInterceptor = (request, next) => {
  return from(inject(AuthenticationService).getToken()).pipe(mergeMap(token => {
    if (token) {
      request = request.clone({
        setHeaders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          Authorization: `Bearer ${token}`
        }
      });
    }
    return next(request);
  }));
};
class BaseService {
  constructor(path) {
    this.path = path;
    this.httpClient = inject(HttpClient);
    this.sharedConfig = inject(SVNL_SHARED_CONFIG);
    this.endpoint = `${this.sharedConfig.serverUrl}/${this.path}`;
  }
  getList() {
    return this.httpClient.get(this.endpoint);
  }
  get(id) {
    return this.httpClient.get(`${this.endpoint}/${id}`);
  }
  create(item) {
    return this.httpClient.post(this.endpoint, item);
  }
  update(item) {
    return this.httpClient.put(`${this.endpoint}/${item.id}`, item);
  }
  remove(item) {
    return this.httpClient.delete(`${this.endpoint}/${item.id}`);
  }
}
class BaseDatabaseService {
  constructor(table) {
    this.table = table;
  }
  getList() {
    return from(this.table.toArray());
  }
  get(id) {
    return from(this.table.get(id)).pipe(mergeMap(item => item ? of(item) : throwError(() => new Error(`No ${this.table.name} found in database for id ${id}`))));
  }
  create(item) {
    return from(this.table.put(item)).pipe(map(() => item));
  }
  update(item) {
    return from(this.table.put(item)).pipe(map(() => item));
  }
  remove(item) {
    return from(this.table.delete(item.id));
  }
  put(items) {
    return this.cleanup(items).pipe(
    // Use dexie.bulkPut to combine add and update
    mergeMap(() => from(this.table.bulkPut(items))), map(() => items));
  }
  cleanup(items) {
    // Check the stored items in the table against the new items, bulkDelete the found deleted ids
    return this.getList().pipe(map(storedItems => storedItems.filter(storedItem => items.find(item => item.id === storedItem.id) === undefined).map(deletedItem => deletedItem.id)), mergeMap(deletedIds => this.table.bulkDelete(deletedIds)));
  }
}
const initialSearchRequest = (pageSize = 5) => ({
  searchFields: [],
  pageIndex: 0,
  pageSize
});
const convertSearchRequestToHttpParams = searchRequest => {
  let params = new HttpParams().append('pageSize', searchRequest.pageSize).append('pageIndex', searchRequest.pageIndex);
  if (searchRequest.sort) {
    params = params.append('sortField', searchRequest.sort.active).append('sortDirection', searchRequest.sort.direction);
  }
  searchRequest.searchFields.map(searchField => params = params.append(searchField.fieldName.toString(), searchField.value ?? ''));
  return params;
};
const createSearchFunction = data => () => of({
  pageIndex: 0,
  pageSize: data.length,
  records: data.length,
  data
});
class BaseSearchService extends BaseService {
  search(searchRequest) {
    return this.httpClient.get(`${this.endpoint}/search`, {
      params: convertSearchRequestToHttpParams(searchRequest)
    });
  }
}
class SvnlControlValueAccessor {
  constructor(controlContainer) {
    this.controlContainer = controlContainer;
    this.formControlName = input.required();
    this.touched = false;
  }
  ngOnInit() {
    if (this.controlContainer && this.controlContainer.control) {
      this.formControl = this.controlContainer.control.get(this.formControlName());
    } else {
      console.warn("Can't find parent FormGroup directive");
    }
  }
  onValueChanged(value) {
    this.markAsTouched();
    this.onChange(value);
  }
  // ControlValueAccessor methods
  onChange(value) {}
  onTouched() {}
  writeValue(value) {
    this.value = value;
  }
  registerOnChange(onChange) {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched) {
    this.onTouched = onTouched;
  }
  setDisabledState(disabled) {
    if (this.formControl) {
      disabled ? this.formControl.disable() : this.formControl.enable();
    }
  }
  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
  compareById(currentValue, compareValue) {
    return currentValue.toString() === compareValue.toString();
  }
  static {
    this.ɵfac = function SvnlControlValueAccessor_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SvnlControlValueAccessor)(i0.ɵɵdirectiveInject(i1.ControlContainer, 13));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SvnlControlValueAccessor,
      selectors: [["ng-component"]],
      inputs: {
        formControlName: [1, "formControlName"]
      },
      standalone: false,
      decls: 1,
      vars: 0,
      template: function SvnlControlValueAccessor_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtext(0, "SvnlControlValueAccessor");
        }
      },
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SvnlControlValueAccessor, [{
    type: Component,
    args: [{
      template: 'SvnlControlValueAccessor',
      standalone: false
    }]
  }], () => [{
    type: i1.ControlContainer,
    decorators: [{
      type: Optional
    }, {
      type: Host
    }, {
      type: SkipSelf
    }]
  }], null);
})();
const PROJ4_RIJKSDRIEHOEK = '+proj=sterea +lat_0=52.15616055555555 +lon_0=5.38763888888889 +k=0.9999079 +x_0=155000 +y_0=463000 +ellps=bessel +towgs84=565.2369,50.0087,465.658,-0.406857330322398,0.350732676542563,-1.8703473836068,4.0812 +units=m +no_defs';
const PROJ4_EPSG_REGISTRY = 'EPSG:4326';
const SPATIAL_REFERENCE_RIJKSDRIEHOEK = 28992;
const SPATIAL_REFERENCE_WEBMERCATOR = 102100;
const formatDate = (input, locale) => _formatDate(input, locale, 'short');
const formatDateLong = (input, locale) => _formatDate(input, locale, 'long');
const formatDateFull = (input, locale) => _formatDate(input, locale, 'full');
const _formatDate = (input, locale, dateStyle) => {
  if (!input) return;
  try {
    return new Intl.DateTimeFormat(locale, {
      dateStyle
    }).format(new Date(input));
  } catch {
    return;
  }
};
const division = input => {
  const parts = input.split('/');
  if (parts.length === 2) {
    const [dividend, divisor] = parts.map(part => parseInt(part));
    if (!Number.isInteger(dividend) || !Number.isInteger(divisor) || divisor === 0) {
      return;
    }
    return dividend / divisor;
  }
  return;
};
const convertDegreesMinutesSeconds = (degreesMinutesSeconds, direction) => {
  if (!degreesMinutesSeconds || !direction) {
    return;
  }
  const coordParts = degreesMinutesSeconds.split(',');
  if (coordParts.length === 3) {
    const [degreesPart, minutesPart, secondsPart] = coordParts;
    const degrees = division(degreesPart);
    const minutes = division(minutesPart);
    const seconds = division(secondsPart);
    if (degrees && minutes && seconds) {
      return (degrees + minutes / 60 + seconds / 3600) * (direction === 'S' || direction === 'W' ? -1 : 1);
    }
  }
  return;
};
const convertExifToLatLng = exif => {
  let latLng;
  if (exif?.GPS) {
    // iOS Exif
    latLng = {
      lat: exif.GPS.Latitude,
      lng: exif.GPS.Longitude
    };
  } else if (exif?.GPSLatitude && exif?.GPSLongitude) {
    // Android Exif
    const lat = convertDegreesMinutesSeconds(exif.GPSLatitude, exif.GPSLatitudeRef);
    const lng = convertDegreesMinutesSeconds(exif.GPSLongitude, exif.GPSLongitudeRef);
    if (lat && lng) {
      latLng = {
        lat,
        lng
      };
    }
  }
  return latLng;
};
const gpsDirectionList = ['N', 'S', 'E', 'W'];
const organisationTypeList = ['SVN', 'FED', 'SPE', 'HSV'];
class ProfileService {
  constructor() {
    this.httpClient = inject(HttpClient);
    this.sharedConfig = inject(SVNL_SHARED_CONFIG);
    this.endpoint = `${this.sharedConfig.serverUrl}/profile`;
  }
  getProfile() {
    return this.httpClient.get(this.endpoint);
  }
  static {
    this.ɵfac = function ProfileService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || ProfileService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ProfileService,
      factory: ProfileService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProfileService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
const ProfileResolver = () => inject(ProfileService).getProfile();
const fullName = person => {
  if (person) {
    const lastName = person.infix ? `${person.infix} ${person.lastName}` : person.lastName;
    return person.firstName ? `${person.firstName} ${lastName}` : lastName;
  }
  return;
};
const rijksdriehoekToWebmercator = mapPoint => {
  const result = proj4(PROJ4_RIJKSDRIEHOEK, PROJ4_EPSG_REGISTRY, [mapPoint.x, mapPoint.y]);
  return {
    lat: result[1],
    lng: result[0]
  };
};
const webmercatorToRijksdriehoek = ({
  lat,
  lng
}) => {
  const result = proj4(PROJ4_EPSG_REGISTRY, PROJ4_RIJKSDRIEHOEK, [lng, lat]);
  return {
    x: result[0],
    y: result[1]
  };
};
class SvnlText {
  constructor(text, translate = true, color = 'primary') {
    if (text instanceof SvnlText) {
      this.text = text.text;
      this.translate = text.translate;
      this.color = text.color;
    } else {
      this.text = text;
      this.translate = translate;
      this.color = color;
    }
  }
}
const permitTypeList = ['KVP', 'VPS'];
const provisionTypeList = ['NVT',
// Nachtvissen
'DHT',
// Derde Hengel
'NVB',
// Nachtverblijf
'SPL',
// Kleine lijst
'EXCEPTION',
// Uitzondering
'CONDITION',
// Bepaling
'CONDITION_FEDERATION' //Federatieve Bepaling
];

/*
 * Public API Surface of SVNL Shared library
 */

/**
 * Generated bundle index. Do not edit.
 */

export { AuthenticationService, BaseDatabaseService, BaseSearchService, BaseService, PROJ4_EPSG_REGISTRY, PROJ4_RIJKSDRIEHOEK, ProfileResolver, ProfileService, SPATIAL_REFERENCE_RIJKSDRIEHOEK, SPATIAL_REFERENCE_WEBMERCATOR, SVNL_SHARED_CONFIG, SvnlControlValueAccessor, SvnlText, convertDegreesMinutesSeconds, convertExifToLatLng, convertSearchRequestToHttpParams, createSearchFunction, filterArrayOnDefined, formatDate, formatDateFull, formatDateLong, fullAddress, fullName, gpsDirectionList, initialSearchRequest, organisationTypeList, permitTypeList, provisionTypeList, rijksdriehoekToWebmercator, tokenInterceptor, webmercatorToRijksdriehoek };
